import { Container } from '@mui/material';
import { Body } from '../styles/page_not_found_style';
import { CardExpiredProposal } from '../components/card_expired_proposal';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { loadProposal } from '../hooks/LoadProposal';
import { IProposalDTO } from '../modules/Proposal/dtos/IProposalDTO';
import { ProposalContext } from '../shared/contexts/Proposal';
import { LoadProposalPage } from './LoadProposalPage';

export function ExpiredProposal() {
  const params = useParams();
  const [proposal, setProposal] = useState<IProposalDTO | null>(null);
  const proposalContext = useContext(ProposalContext);
  const navigate = useNavigate();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const tokenRecaptcha = useCallback(async () => {
    if (!executeRecaptcha) {
      throw Error('Ocorreu um erro com o captcha, tente novamente.');
    }
    return await executeRecaptcha('load_proposal');
  }, [executeRecaptcha]);

  useEffect(() => {
    (async () => {
      if (!params.id) return;

      if (!proposalContext.proposal) {
        const captcha_token = await tokenRecaptcha();

        await loadProposal({
          proposal_id: params.id,
          saveProposal: proposalContext.saveProposal,
          navigate,
          captcha_token,
        });
      }
      setProposal(proposalContext.proposal);
    })();
  }, [
    navigate,
    params.id,
    proposalContext.proposal,
    proposalContext.saveProposal,
    tokenRecaptcha,
  ]);

  return proposal ? (
    <React.Fragment>
      <Helmet>
        <title>Proposta Online - Proposta Expirada</title>
        <meta name="description" content="Proposta Online Expirada | PRECATO" />
      </Helmet>
      <Body>
        <Container maxWidth="sm">
          <CardExpiredProposal
            closer_phone={proposal.closer_phone}
            nome={proposal.nome}
          />
        </Container>
      </Body>
    </React.Fragment>
  ) : (
    <LoadProposalPage />
  );
}
