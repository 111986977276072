import styled from 'styled-components';

export const TimerBody = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  margin-top: 2rem;
`;
export const TimerBox = styled.div`
  background: #052545;
  padding: 1rem 0 1rem 0;
`;
