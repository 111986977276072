import styled from 'styled-components';

interface IBodyContainerProps {
  padding?: boolean;
}

export const BodyContainer = styled.div<IBodyContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: ${(props) => (props.padding ? '1rem 1.5rem 9rem 1.5rem' : 'none')};
  flex-wrap: wrap;
  overflow-wrap: anywhere;
`;

export const ListVideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
