import { YoutubeIframe } from './style';

interface IYoutubeProps {
  embedId: string;
  width?: string;
}

export const Youtube = ({ embedId, width }: IYoutubeProps) => {
  return (
    <YoutubeIframe
      width={width}
      src={`https://www.youtube.com/embed/${embedId}`}
      title="YouTube video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    />
  );
};
