import { NavigateFunction } from 'react-router-dom';
import { HubspotProvider } from '../../modules/Hubspot/providers/implementations/HubspotProvider';
import { ProposalProvider } from '../../modules/Proposal/providers/implementations/ProposalProvider';

interface ILoadProposal {
  proposal_id: string;
  saveProposal: Function | null;
  navigate: NavigateFunction;
  captcha_token: string;
}

export const loadProposal = async ({
  proposal_id,
  saveProposal,
  navigate,
  captcha_token,
}: ILoadProposal) => {
  const proposalProvider = new ProposalProvider();
  const hubspotProvider = new HubspotProvider();

  try {
    const proposal = await proposalProvider.getProposal(
      proposal_id,
      captcha_token,
    );
    if (!saveProposal) return;

    hubspotProvider.identify({ email: proposal.hubspot_email });

    saveProposal(proposal);
  } catch (e) {
    navigate('/notfound');
  }
};
