import { Divider, Typography } from '@mui/material';
import React from 'react';
import { BodyContainer } from '../../../components/BodyStyle/style';
import { CardMenu } from '../../../components/CardMenu';

import { FirstItem, ListDescountsDetail, Row, SecondItem } from './style';
import { IProposalDTO } from '../../../modules/Proposal/dtos/IProposalDTO';
import ReactECharts from 'echarts-for-react';

interface Props {
  proposal: IProposalDTO | null;
}

export const Body = ({ proposal }: Props) => {
  const data = [
    { name: 'Sua Parte', value: proposal?.valor_liquido },
    { name: 'IRPF', value: proposal?.valor_imposto_renda },
    { name: 'PSS', value: proposal?.valor_pss },
    { name: 'Advogado', value: proposal?.valor_honorario },

    // Outros itens...
  ].filter((item) => item.value !== null && item.value !== 0);

  const options = {
    // Configurações do gráfico de pizza...
    tooltip: {
      formatter: (params: any) => {
        const value = params.value as number;
        return (
          params.name +
          '<br />' +
          +params.percent +
          '%' +
          '<br />' +
          value.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })
        );
      },
    },
    series: [
      {
        name: 'Série 1',
        type: 'pie',
        radius: ['0%', '40%'],
        data: data.map((item) => ({
          name: item.name,
          value: item.value,
        })),
        label: {
          show: true,
          position: 'outside',
          formatter: '{b} \n ({d}%)',
          fontSize: 12,

          fontFamily: 'Poppins',
        },
        labelLine: {
          show: true,
          length: 3, // Define o comprimento da linha que conecta o rótulo à fatia
        },
        itemStyle: {
          color: function (params: any) {
            const colors = ['#477BFF', '#F23F1F', '#285066', '#995600'];
            return colors[params.dataIndex % colors.length];
          },
        },
      },
    ],
  };

  return (
    <BodyContainer padding={true}>
      <Typography variant="h6" fontWeight="bold">
        Proposta detalhada
      </Typography>
      <div>
        <Typography variant="subtitle1" sx={{ color: '#505050' }}>
          {proposal?.nome_closer === 'PRECATO'
            ? 'Saldo disponível'
            : 'Nossa proposta'}
        </Typography>
        <Typography
          variant="subtitle1"
          fontWeight="bold"
          sx={{
            color: '#1479DE',
            fontSize: '2rem',
          }}
        >
          {proposal?.valor_proposta.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}
        </Typography>
        {proposal?.nome_closer === 'PRECATO' ? (
          <></>
        ) : (
          <div>
            <Typography variant="subtitle1" sx={{ color: '#505050' }}>
              Valor líquido do precatório
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: '#505050', fontSize: '1.3rem' }}
            >
              {proposal?.valor_liquido.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Typography>
          </div>
        )}
      </div>
      <CardMenu
        title={
          <Typography
            variant="subtitle1"
            fontWeight="bold"
            sx={{ color: 'black' }}
          >
            Sobre a proposta
          </Typography>
        }
        disabled={true}
      >
        <Typography variant="body2" sx={{ color: '#555555' }}>
          Sua proposta é feita sobre o valor liquido do seu precatóro, ou seja,
          sobre o valor restante após os descontos como honorários do seu
          advogado e imposto de renda.
        </Typography>
      </CardMenu>
      <CardMenu
        title={
          <Typography
            variant="subtitle1"
            fontWeight="bold"
            sx={{ color: 'black' }}
          >
            O seu precatório não é só seu!
          </Typography>
        }
        disabled={true}
      >
        <Typography variant="body2" sx={{ color: '#555555' }}>
          No seu precatório pode haver valores a serem pagos para o seu
          advogado, para o imposto de renda entre outros. Entenda os valores no
          gráfico e no extrato abaixo.
        </Typography>
      </CardMenu>
      {proposal?.nome_closer === 'PRECATO' ? (
        <></>
      ) : (
        <div>
          <ReactECharts option={options} />

          <ListDescountsDetail>
            <Divider />
            <div>
              <Typography variant="h6" fontWeight="bold">
                Sobre Seu Precatório
              </Typography>
            </div>

            <div>
              <Typography variant="h6" fontWeight="bold">
                Descontos
              </Typography>
            </div>
            <Row>
              <FirstItem>
                <Typography variant="subtitle2">Honorários</Typography>
                <Typography variant="caption">do seu advogado</Typography>
              </FirstItem>
              <SecondItem>
                <Typography variant="body1" fontWeight="bold">
                  -
                  {proposal?.valor_honorario.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Typography>
              </SecondItem>
            </Row>
            <Row>
              <FirstItem>
                <Typography variant="subtitle2">Imposto de Renda</Typography>
              </FirstItem>
              <SecondItem>
                <Typography variant="body1" fontWeight="bold">
                  -
                  {proposal?.valor_imposto_renda.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Typography>
              </SecondItem>
            </Row>
            <Row>
              <FirstItem>
                <Typography variant="subtitle2">PSS</Typography>
              </FirstItem>
              <SecondItem>
                <Typography variant="body1" fontWeight="bold">
                  -
                  {proposal?.valor_pss.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Typography>
              </SecondItem>
            </Row>
            <Row>
              <FirstItem>
                <Typography variant="h6" fontWeight="bold">
                  Valor Liquido
                </Typography>
              </FirstItem>
              <SecondItem>
                <Typography variant="h6" fontWeight="bold">
                  {proposal?.valor_liquido.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Typography>
              </SecondItem>
            </Row>
          </ListDescountsDetail>
        </div>
      )}
    </BodyContainer>
  );
};
