import { Card, CardActionArea, CardContent } from '@mui/material';
import { ReactNode } from 'react';

interface ICardMenuProps {
  tool?: ReactNode;
  title: ReactNode;
  children: ReactNode;
  background?: string;
  titleColor?: string;
  trailing?: ReactNode;
  action?: Function;
  disabled?: boolean;
}

export const CardMenu = ({
  tool = '',
  children,
  title,
  background = '#F3f3f3',
  trailing,
  action = () => {},
  disabled = false,
}: ICardMenuProps) => {
  return (
    <Card
      elevation={0}
      sx={{ background, borderRadius: '0.75rem', padding: '.5rem' }}
    >
      <CardActionArea
        disabled={disabled}
        onClick={() => {
          action();
        }}
      >
        <CardContent>
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            {tool}
            <div
              style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
            >
              {title}
              {children}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                flexGrow: 1,
                alignItems: 'center',
              }}
            >
              {trailing ?? ''}
            </div>
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
