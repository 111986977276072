import { IHubspotIdentifyDTO } from '../../dtos/IHubspotIdentifyDTO';
import { IHubspotProvider } from '../models/IHubspotProvider';

export class HubspotProvider implements IHubspotProvider {
  pageView(): void {
    //@ts-ignore
    var _hsq = (window._hsq = window._hsq || []);
    _hsq.push(['trackPageView']);
  }

  identify({ email }: IHubspotIdentifyDTO): void {
    //@ts-ignore
    var _hsq = (window._hsq = window._hsq || []);

    _hsq.push([
      'identify',
      {
        email,
      },
    ]);
  }
}
