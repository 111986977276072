import axios from 'axios';
import { IProposalDTO } from '../../dtos/IProposalDTO';
import { IProposalProvider } from '../models/IProposalProvider';

export class ProposalProvider implements IProposalProvider {
  async getProposal(
    proposal_id: string,
    captcha_token: string,
  ): Promise<IProposalDTO> {
    const { data } = await axios.post<IProposalDTO>(
      `${process.env.REACT_APP_API_URL}/online_proposal/read/${proposal_id}`,
      {
        captcha_token,
      },
      {
        headers: {},
      },
    );
    return data;
  }
}
