import { Route, Routes } from 'react-router-dom';
import { GoogleAnalyticsMiddleware } from '../../hooks/GoogleAnalytics';
import { ExpiredProposal } from '../../pages/expired_proposal_page';
import { ProposalDetail } from '../../pages/ProposalDetail';
import { ProposalPage } from '../../pages/ProposalPage';
import { ShowPrecatorioPage } from '../../pages/ShowPrecatorioPage';
import { ShowProposalPage } from '../../pages/ShowProposalPage';
import { ProposalContextProvider } from '../../shared/contexts/Proposal';

export const CloserProposalRoutes = () => {
  const GTM_ID = 'GTM-5252W5J';
  return (
    <GoogleAnalyticsMiddleware gtmId={GTM_ID} trackOn={false}>
      <ProposalContextProvider>
        <Routes>
          <Route path="/" element={<ProposalPage />} />
          <Route path="/show" element={<ShowProposalPage />} />
          <Route path="/show/precatorio" element={<ShowPrecatorioPage />} />
          <Route path="/show/proposal" element={<ProposalDetail />} />
          <Route path="/expired" element={<ExpiredProposal />} />
        </Routes>
      </ProposalContextProvider>
    </GoogleAnalyticsMiddleware>
  );
};
