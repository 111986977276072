import { Divider, Typography } from '@mui/material';
import React from 'react';
import { BodyContainer } from '../../../components/BodyStyle/style';
import { IProposalDTO } from '../../../modules/Proposal/dtos/IProposalDTO';
import {
  PrecatorioContainer,
  PrecatorioList,
  PrecatorioListItemFirstContent,
  PrecatorioListItemSecondContent,
} from './style';

interface Props {
  proposal: IProposalDTO | null;
}

export const Body = ({ proposal }: Props) => {
  return (
    <React.Fragment>
      <BodyContainer padding={true}>
        <Typography
          variant="h5"
          fontWeight={'bold'}
          sx={{ paddingBottom: '2rem' }}
        >
          Seu Precatório
        </Typography>
        <PrecatorioContainer>
          {proposal?.nome_closer === 'PRECATO' ? (
            <></>
          ) : (
            <PrecatorioList>
              <PrecatorioListItemFirstContent>
                <Typography variant="body2">Valor Bruto Atualizado</Typography>
              </PrecatorioListItemFirstContent>
              <PrecatorioListItemSecondContent>
                <Typography variant="body1" fontWeight={'bold'}>
                  {proposal?.valor_bruto.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Typography>
              </PrecatorioListItemSecondContent>
            </PrecatorioList>
          )}
          <Divider />
          <PrecatorioList>
            <PrecatorioListItemFirstContent>
              <Typography variant="body2">TRF/TJ</Typography>
            </PrecatorioListItemFirstContent>
            <PrecatorioListItemSecondContent>
              <Typography variant="body1" fontWeight={'bold'}>
                {proposal?.trf}
              </Typography>
            </PrecatorioListItemSecondContent>
          </PrecatorioList>
          <Divider />
          <PrecatorioList>
            <PrecatorioListItemFirstContent>
              <Typography variant="body2">Vara</Typography>
            </PrecatorioListItemFirstContent>
            <PrecatorioListItemSecondContent>
              <Typography variant="body1" fontWeight={'bold'}>
                {proposal?.vara}
              </Typography>
            </PrecatorioListItemSecondContent>
          </PrecatorioList>
          <Divider />
          <PrecatorioList>
            <PrecatorioListItemFirstContent>
              <Typography variant="body2">Ente Devedor</Typography>
            </PrecatorioListItemFirstContent>
            <PrecatorioListItemSecondContent>
              <Typography variant="body1" fontWeight={'bold'}>
                {proposal?.ente_devedor}
              </Typography>
            </PrecatorioListItemSecondContent>
          </PrecatorioList>
          <Divider />
          <PrecatorioList>
            <PrecatorioListItemFirstContent>
              <Typography variant="body2">Inicio do Processo</Typography>
            </PrecatorioListItemFirstContent>
            <PrecatorioListItemSecondContent>
              <Typography variant="body1" fontWeight={'bold'}>
                {proposal?.inicio_processo}
              </Typography>
            </PrecatorioListItemSecondContent>
          </PrecatorioList>
          <Divider />
          <PrecatorioList>
            <PrecatorioListItemFirstContent>
              <Typography variant="body2">Data de Expedição </Typography>
            </PrecatorioListItemFirstContent>
            <PrecatorioListItemSecondContent>
              <Typography variant="body1" fontWeight={'bold'}>
                {proposal?.data_expedicao}
              </Typography>
            </PrecatorioListItemSecondContent>
          </PrecatorioList>
          <Divider />
          <PrecatorioList>
            <PrecatorioListItemFirstContent>
              <Typography variant="body2">LOA</Typography>
            </PrecatorioListItemFirstContent>
            <PrecatorioListItemSecondContent>
              <Typography variant="body1" fontWeight={'bold'}>
                {proposal?.loa}
              </Typography>
            </PrecatorioListItemSecondContent>
          </PrecatorioList>
          <Divider />
          <PrecatorioList>
            <PrecatorioListItemFirstContent>
              <Typography variant="body2">Motivo</Typography>
            </PrecatorioListItemFirstContent>
            <PrecatorioListItemSecondContent>
              <Typography variant="body1" fontWeight={'bold'}>
                {proposal?.motivo}
              </Typography>
            </PrecatorioListItemSecondContent>
          </PrecatorioList>
          <Divider />
          <PrecatorioList>
            <PrecatorioListItemFirstContent>
              <Typography variant="body2">Advogado</Typography>
            </PrecatorioListItemFirstContent>
            <PrecatorioListItemSecondContent>
              <Typography variant="body1" fontWeight={'bold'}>
                {proposal?.advogado}
              </Typography>
            </PrecatorioListItemSecondContent>
          </PrecatorioList>
        </PrecatorioContainer>
      </BodyContainer>
    </React.Fragment>
  );
};
