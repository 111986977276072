import { Container } from '@mui/material';
import { LoadProposalCard } from '../../components/card_load_proposal';
import { Body } from '../../styles/page_not_found_style';

export const LoadProposalPage = () => {
  return (
    <Body>
      <Container maxWidth="sm">
        <LoadProposalCard />
      </Container>
    </Body>
  );
};
