import styled from 'styled-components';

export const PrecatorioContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export const PrecatorioList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const PrecatorioListItemFirstContent = styled.div`
  display: flex;
  flex: 4;
  flex-wrap: wrap;
`;
export const PrecatorioListItemSecondContent = styled.div`
  display: flex;
  flex: 5;
  flex-wrap: wrap;
  justify-content: end;
  text-align: end;
`;
