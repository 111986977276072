import { Button, Paper, Typography } from '@mui/material';
import { CardPageExpiredProposal } from '../styles/page_expired_proposal_style';
import { ReactComponent as LogoPrecatoAzul } from '../assets/images/logo_precato_azul.svg';
import { HourglassBottom } from '@mui/icons-material';

interface IExpiredProposalCardProps {
  nome: string;
  closer_phone: string;
}

export function CardExpiredProposal(props: IExpiredProposalCardProps) {
  return (
    <Paper>
      <CardPageExpiredProposal>
        <LogoPrecatoAzul height={'2em'} />
        <HourglassBottom color="primary" sx={{ fontSize: '6rem' }} />
        <Typography variant="h5" style={{ textAlign: 'center' }}>
          Olá, {props.nome}.
        </Typography>

        <Typography variant="h5" textAlign="center">
          Sua proposta expirou em:
        </Typography>
        <Typography variant="h5" textAlign="center">
          14 de Fevereiro de 2023
        </Typography>
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={() => {
            window.location.replace(
              `https://wa.me/55${props.closer_phone}?text=Quero+uma+nova+proposta`,
            );
          }}
        >
          Solictar nova proposta
        </Button>
      </CardPageExpiredProposal>
    </Paper>
  );
}
