import { Button } from '@mui/material';
import { ExpireTimer } from '../../../components/ExpireTimer';
import { useNavigate, useParams } from 'react-router-dom';
import { IProposalDTO } from '../../../modules/Proposal/dtos/IProposalDTO';

interface Props {
  proposal: IProposalDTO | null;
}

export const Footer = ({ proposal }: Props) => {
  const navigate = useNavigate();
  const params = useParams();
  return (
    <div>
      <ExpireTimer
        expireDate={proposal?.data_expiracao ?? 0}
        otherContent={
          <Button
            onClick={() => {
              navigate(`/${params.id}/show/proposal`);
            }}
            variant="contained"
            color="primary"
            size="large"
            sx={{
              width: '100%',
              borderRadius: 0,
              boxShadow: 'none',
            }}
          >
            Ver Proposta
          </Button>
        }
      />
    </div>
  );
};
