import { createTheme } from '@mui/material';
import '@fontsource/poppins';

export default createTheme({
  typography: {
    fontFamily: ['Poppins'].join(','),
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#1479DE',
    },
    secondary: {
      main: '#164C81',
    },
  },
});
