import styled from 'styled-components';

export const HeaderContent = styled.div`
  padding: 0.25rem;
  padding-left: 0.5rem;
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
`;

export const Trailing = styled.div`
  display: flex;
  flex: 1;
  justify-content: end;
`;

export const TitleBox = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const ToolBox = styled.div`
  display: flex;
  flex: 1;
`;
