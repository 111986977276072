import React, { ReactNode } from 'react';
import { HorizontalConfigs } from './styled';

interface IHorizontalCarousel {
  children: ReactNode;
}

export const HorizontalCarousel = ({ children }: IHorizontalCarousel) => {
  return <HorizontalConfigs>{children}</HorizontalConfigs>;
};
