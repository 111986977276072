import { Paper, CircularProgress, Typography } from '@mui/material';
import { CardPageNotFound } from '../styles/page_not_found_style';
import { ReactComponent as LogoPrecatoAzul } from '../assets/images/logo_precato_azul.svg';
import { SearchOff } from '@mui/icons-material';

export function LoadProposalCard() {
  return (
    <Paper>
      <CardPageNotFound>
        <LogoPrecatoAzul height={'2em'} />

        <CircularProgress />
        <Typography variant="h6" textAlign="center">
          Carregando...
        </Typography>
      </CardPageNotFound>
    </Paper>
  );
}
