import { useTheme } from '@mui/material';
import styled from 'styled-components';

export const Body = styled.div`
  background-color: ${() => useTheme().palette.primary.main};
  display: flex;
  height: 100vh;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

export const CardPageNotFound = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  gap: 2rem;
`;
