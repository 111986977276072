import styled from 'styled-components';

export const HorizontalConfigs = styled.div`
  &::-webkit-scrollbar {
    display: none;
  }
  display: flex;
  flex-direction: row;
  padding-left: 0;
  gap: 1rem;
  justify-content: start;
  overflow-x: auto;
`;
