import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { GoogleAnalyticsMiddleware } from '../hooks/GoogleAnalytics';
import NotFoundPage from '../pages/not_found_proposal_page';
import { ProposalRoutes } from './Proposal';
import { CloserProposalRoutes } from './Proposal/closer';

const Provider = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/closer/:id/*" element={<CloserProposalRoutes />} />
        <Route path="/:id/*" element={<ProposalRoutes />} />
        <Route path="/notfound" element={<NotFoundPage />} />
        <Route path="*" element={<Navigate to="/notfound" />} />
      </Routes>
    </BrowserRouter>
  );
};
export default Provider;
