import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';
import { CardMenu } from '../../../components/CardMenu';
import { BodyContainer } from '../../../components/BodyStyle/style';
import * as Icon from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { IProposalDTO } from '../../../modules/Proposal/dtos/IProposalDTO';
import { IconsColumn } from './style';

interface Props {
  proposal: IProposalDTO | null;
}

export const Body = ({ proposal }: Props) => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <BodyContainer padding={true}>
        <div>
          <Typography variant="subtitle1">
            {proposal?.nome_closer === 'PRECATO'
              ? 'Saldo disponível'
              : ' Valor liquido do precatório'}
          </Typography>
          <Typography sx={{ fontWeight: 600 }} variant="h4">
            {proposal?.nome_closer === 'PRECATO'
              ? proposal?.valor_proposta.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })
              : proposal?.valor_liquido.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
          </Typography>
        </div>

        <List disablePadding={true}>
          <ListItem disablePadding={true}>
            <ListItemButton style={{ paddingLeft: 0 }}>
              <ListItemIcon>
                <Icon.AttachMoney />
              </ListItemIcon>
              <ListItemText
                sx={{ color: '#707070', fontWeight: 800 }}
                primary="Informações do Precatório"
                secondary="Ver detalhes"
                onClick={() => {
                  navigate(`./precatorio`);
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding={true}>
            <ListItemButton style={{ paddingLeft: 0 }}>
              <ListItemIcon>
                <Icon.BarChart />
              </ListItemIcon>
              <ListItemText
                sx={{ color: '#707070' }}
                primary="Informações da Proposta"
                secondary="Ver detalhes"
                onClick={() => {
                  navigate(`./proposal`);
                }}
              />
            </ListItemButton>
          </ListItem>
        </List>

        <CardMenu
          background="#007FFF"
          title={
            <Typography
              variant="subtitle2"
              sx={{ fontSize: '0.875rem', color: 'white' }}
            >
              {proposal?.nome_closer === 'PRECATO'
                ? 'Saldo disponível'
                : 'Proposta'}
            </Typography>
          }
          trailing={<Icon.ArrowForwardIos sx={{ color: '#003A75' }} />}
          action={() => {
            navigate(`./proposal`);
          }}
        >
          <div>
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: '1.5rem',
                fontWeight: 'bold',
                color: 'white',
              }}
            >
              {proposal?.valor_proposta.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Typography>
          </div>
        </CardMenu>
        <CardMenu
          tool={
            <IconsColumn>
              <Icon.Call sx={{ color: 'white' }} />
            </IconsColumn>
          }
          background="#25D366"
          title={
            <Typography
              variant="subtitle2"
              sx={{ fontSize: '0.875rem', color: 'white' }}
            >
              {'Negociação'}
            </Typography>
          }
          trailing={<Icon.ArrowForwardIos sx={{ color: '#082D16' }} />}
          action={() => {
            window.location.replace(
              `https://wa.me/55${proposal?.closer_phone}?text=Quero+negociar+minha+proposta+de+antecipa%C3%A7%C3%A3o+de+precat%C3%B3rio`,
            );
          }}
        >
          <div>
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: '1rem',
                fontWeight: 'bold',
                color: 'white',
                gap: '0.5rem',
              }}
            >
              Conversar com {proposal?.nome_closer}
            </Typography>
          </div>
        </CardMenu>
      </BodyContainer>
    </React.Fragment>
  );
};
