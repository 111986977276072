import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Header } from './Header';
import { Body } from './Body';
import { Footer } from './Footer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ProposalContext } from '../../shared/contexts/Proposal';
import { IProposalDTO } from '../../modules/Proposal/dtos/IProposalDTO';
import { loadProposal } from '../../hooks/LoadProposal';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Helmet } from 'react-helmet';
import { LoadProposalCard } from '../../components/card_load_proposal';
import { LoadProposalPage } from '../LoadProposalPage';
import { CloserAlertDialog } from '../../components/CloserAlertDialog';

export const ProposalPage = () => {
  const params = useParams();
  const [proposal, setProposal] = useState<IProposalDTO | null>(null);
  const proposalContext = useContext(ProposalContext);
  const navigate = useNavigate();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const tokenRecaptcha = useCallback(async () => {
    if (!executeRecaptcha) {
      throw Error('Ocorreu um erro com o captcha, tente novamente.');
    }
    return await executeRecaptcha('load_proposal');
  }, [executeRecaptcha]);

  useEffect(() => {
    (async () => {
      if (!params.id) return;

      if (!proposalContext.proposal) {
        const captcha_token = await tokenRecaptcha();

        await loadProposal({
          proposal_id: params.id,
          saveProposal: proposalContext.saveProposal,
          navigate,
          captcha_token,
        });
      }
      setProposal(proposalContext.proposal);
    })();
  }, [
    navigate,
    params.id,
    proposalContext.proposal,
    proposalContext.saveProposal,
    tokenRecaptcha,
  ]);

  const [openCloserAlertDialog, setOpenCloserDialog] = useState(false);
  const location = useLocation();

  const handleClose = () => {
    setOpenCloserDialog(false);
  };

  useEffect(() => {
    if (location.pathname.includes('/closer/')) {
      setOpenCloserDialog(true);
    }
  }, [location]);

  return proposal ? (
    <React.Fragment>
      <Helmet>
        <title>Proposta Online - Página Inicial</title>
        <meta name="description" content="Proposta Online | PRECATO" />
      </Helmet>
      <CloserAlertDialog
        open={openCloserAlertDialog}
        handleClose={handleClose}
      />
      <Header proposal={proposal} />
      <Body />
      <Footer proposal={proposal} />
    </React.Fragment>
  ) : (
    <LoadProposalPage />
  );
};
