import { AppBar, IconButton, Typography } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { HeaderContent, TitleBox, ToolBox, Trailing } from './style';
import { useNavigate } from 'react-router-dom';
import { ReactNode } from 'react';

interface IHeaderWithButtonBack {
  title?: String;
  trailing?: ReactNode;
}
export const HeaderWithButtonBack = ({
  title = '',
  trailing = <div style={{ minWidth: '1rem', minHeight: '1rem' }}></div>,
}: IHeaderWithButtonBack) => {
  const navigate = useNavigate();
  return (
    <AppBar position="static" elevation={0} sx={{ background: 'none' }}>
      <HeaderContent>
        <ToolBox>
          <IconButton onClick={() => navigate(-1)}>
            <Icons.ArrowBackIos />
          </IconButton>
        </ToolBox>
        <TitleBox>
          <Typography fontWeight={600} color="#757575" variant="subtitle1">
            {title}
          </Typography>
        </TitleBox>
        <Trailing>{trailing}</Trailing>
      </HeaderContent>
    </AppBar>
  );
};
