import { Typography } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { TimerBody, TimerBox } from './style';
import { useNavigate, useParams } from 'react-router-dom';

export const useTimer = (targetDate: string | number | Date) => {
  const futureDate = new Date(targetDate).getTime();
  const [timer, setTimer] = useState(futureDate - new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(futureDate - new Date().getTime());
    }, 1000);
    return () => clearInterval(interval);
  }, [futureDate, timer]);

  return getReturnValues(timer);
};

const getReturnValues = (countDown: number) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
  const timestamp = countDown;
  return {
    timestamp,
    days,
    hours,
    minutes,
    seconds,
  };
};

interface IExpireTimerProps {
  expireDate: string | number | Date;
  otherContent?: ReactNode;
}

export const ExpireTimer = ({
  expireDate,
  otherContent = '',
}: IExpireTimerProps) => {
  const { days, hours, minutes, seconds } = useTimer(new Date(expireDate));

  const navigate = useNavigate();
  const params = useParams();
  useEffect(() => {
    const isExpired = () => {
      return days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0;
    };
    console.log(expireDate);

    if (isExpired() && expireDate > 0) {
      navigate(`/${params.id}/expired`);
    }
  }, [days, expireDate, hours, minutes, navigate, params.id, seconds]);

  return (
    <TimerBody>
      {otherContent}
      <TimerBox>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="subtitle1" color="white" fontWeight={600}>
            {'Sua proposta expira em:'}
          </Typography>
          <Typography variant="subtitle1" sx={{ color: 'white' }}>
            {`
            ${days > 0 ? `${days} ${days !== 1 ? 'dias' : 'dia'}` : ''}
            ${hours > 0 ? `${hours} ${hours !== 1 ? 'horas' : 'horas'}` : ''}
            ${
              minutes > 0
                ? `${minutes} ${minutes !== 1 ? 'minutos' : 'minutos'}`
                : ''
            }
            ${
              seconds > 0
                ? `${seconds} ${seconds !== 1 ? 'segundos' : 'segundo'}`
                : ''
            }
          `}
          </Typography>
          <Typography
            variant="caption"
            color="white"
            sx={{ marginTop: '0.5rem' }}
          >
            * Essa proposta esta sujeita a revisão
          </Typography>
        </div>
      </TimerBox>
    </TimerBody>
  );
};
