import styled from 'styled-components';

interface IYoutubeIframeProps {
  width?: string;
}
export const YoutubeIframe = styled.iframe<IYoutubeIframeProps>`
  aspect-ratio: 16 / 9;
  display: absolute;
  height: 100%;
  border: 0;
  border-radius: 4px;
  width: ${(props) => props.width || '100%'};
`;
