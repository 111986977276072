import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

interface CloserAlertDialogProps {
  open: boolean;
  handleClose(): void;
}

export const CloserAlertDialog = (props: CloserAlertDialogProps) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {'Atenção! Não envie esse link para o credor!'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Não copie o link dessa página para enviar ao credor!
          <br /> Esta página é apenas para visualização do Closer.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} autoFocus>
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
