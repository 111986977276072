import { AppBar, Typography } from '@mui/material';
import { HeaderContent } from './style';
import { IProposalDTO } from '../../../modules/Proposal/dtos/IProposalDTO';
import { ReactComponent as LogoPrecatoBranco } from '../../../assets/images/logo_precato_branca.svg';

interface Props {
  proposal: IProposalDTO | null;
}

export const Header = ({ proposal }: Props) => {
  const FirstName = (): JSX.Element => {
    const firstNameLowercase = proposal?.nome.split(' ')[0].toLowerCase() ?? '';
    const formattedFirstName =
      firstNameLowercase.charAt(0).toUpperCase() + firstNameLowercase.slice(1);

    return <>{formattedFirstName}</>;
  };

  return (
    <AppBar position="static" elevation={0}>
      <HeaderContent>
        <LogoPrecatoBranco />
        <Typography variant="h5" sx={{ fontWeight: 800, marginTop: '1rem' }}>
          Olá, <FirstName />
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ fontWeight: 600, marginTop: '0.5rem' }}
        >
          Bem vindo a Precato!
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ fontWeight: 600, marginTop: '0.4rem' }}
        >
          Clique no botão abaixo para acessar a sua proposta
        </Typography>
      </HeaderContent>
    </AppBar>
  );
};
