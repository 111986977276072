import { ExpireTimer } from '../../../components/ExpireTimer';
import { IProposalDTO } from '../../../modules/Proposal/dtos/IProposalDTO';

interface Props {
  proposal: IProposalDTO | null;
}

export const Footer = ({ proposal }: Props) => {
  return <ExpireTimer expireDate={proposal?.data_expiracao ?? 0} />;
};
