import React, { useState } from 'react';
import { IProposalDTO } from '../../../modules/Proposal/dtos/IProposalDTO';

interface IProposalContextInterface {
  proposal: IProposalDTO | null;
  saveProposal: Function | null;
}

export const ProposalContext = React.createContext<IProposalContextInterface>({
  proposal: null,
  saveProposal: null,
});

interface Props {
  children: React.ReactNode;
}

export const ProposalContextProvider = ({ children }: Props) => {
  const [proposal, setProposal] = useState<IProposalDTO | null>(null);

  const saveProposal = (value: IProposalDTO) => {
    setProposal(value);
  };

  return (
    <ProposalContext.Provider value={{ proposal, saveProposal }}>
      {children}
    </ProposalContext.Provider>
  );
};
