import { useCallback, useContext, useEffect, useState } from 'react';
import { Body } from './Body';
import { Header } from './Header';
import { Footer } from './Footer';
import { useNavigate, useParams } from 'react-router-dom';
import { IProposalDTO } from '../../modules/Proposal/dtos/IProposalDTO';
import { ProposalContext } from '../../shared/contexts/Proposal';
import { loadProposal } from '../../hooks/LoadProposal';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Helmet } from 'react-helmet';
import { LoadProposalPage } from '../LoadProposalPage';

export const ShowProposalPage = () => {
  const params = useParams();
  const [proposal, setProposal] = useState<IProposalDTO | null>(null);
  const proposalContext = useContext(ProposalContext);
  const navigate = useNavigate();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const tokenRecaptcha = useCallback(async () => {
    if (!executeRecaptcha) {
      throw Error('Ocorreu um erro com o captcha, tente novamente.');
    }
    return await executeRecaptcha('load_proposal');
  }, [executeRecaptcha]);

  useEffect(() => {
    (async () => {
      if (!params.id) return;

      if (!proposalContext.proposal) {
        const captcha_token = await tokenRecaptcha();

        await loadProposal({
          proposal_id: params.id,
          saveProposal: proposalContext.saveProposal,
          navigate,
          captcha_token,
        });
      }
      setProposal(proposalContext.proposal);
    })();
  }, [
    navigate,
    params.id,
    proposalContext.proposal,
    proposalContext.saveProposal,
    tokenRecaptcha,
  ]);

  return proposal ? (
    <div>
      <Helmet>
        <title>
          Proposta Online - Menu Inicial - Proposta:{' '}
          {proposal?.valor_proposta.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}
        </title>
        <meta name="description" content="Menu da Proposta Online | PRECATO" />
      </Helmet>
      <Header />
      <Body proposal={proposal} />
      <Footer proposal={proposal} />
    </div>
  ) : (
    <LoadProposalPage />
  );
};
