import { useEffect } from 'react';
import ReactGA4 from 'react-ga4';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { HubspotProvider } from '../modules/Hubspot/providers/implementations/HubspotProvider';

interface IGoogleAnalyticsMiddlewareProps {
  children: JSX.Element;
  gtmId?: string;
  trackOn?: boolean;
}

export const GoogleAnalyticsMiddleware = (
  props: IGoogleAnalyticsMiddlewareProps,
) => {
  const location = useLocation();

  useEffect(() => {
    if (!props.trackOn) return;

    const script = document.createElement('script') as HTMLScriptElement; // <--- Aqui fazemos a conversão de tipo
    script.src = '//js.hs-scripts.com/7857327.js';
    script.async = true;
    script.defer = true;
    script.id = 'hs-script-loader';
    script.type = 'text/javascript';

    document.body.appendChild(script);

    const hubspotProvider = new HubspotProvider();
    hubspotProvider.pageView();

    ReactGA4.send({
      hitType: 'pageview',
      page: location.pathname,
      title: document.title,
    });

    if (props.gtmId) {
      const tagManagerArgs = {
        gtmId: props.gtmId,
      };
      TagManager.initialize(tagManagerArgs);
    }
  }, [location, props.gtmId, props.trackOn]);

  return props.children;
};

export const googleClientId = () =>
  new Promise<string>((resolve) => {
    ReactGA4.ga((tracker: any) => {
      resolve(tracker.get('clientId'));
    });
  });
