import { Button, Paper, Typography } from '@mui/material';
import { CardPageNotFound } from '../styles/page_not_found_style';
import { ReactComponent as LogoPrecatoAzul } from '../assets/images/logo_precato_azul.svg';
import { SearchOff } from '@mui/icons-material';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export function NotFoundCard() {
  return (
    <Paper>
      <CardPageNotFound>
        <LogoPrecatoAzul height={'2em'} />
        <SearchOff color="primary" sx={{ fontSize: '6rem' }} />

        <Typography variant="h5" textAlign="center">
          Infelizmente não encontramos uma proposta
        </Typography>
        <Button
          onClick={() => {
            window.location.replace(`https://conheca.precato.com.br/`);
          }}
          color="primary"
          variant="contained"
          size="large"
        >
          Consultar meu precatório
        </Button>
      </CardPageNotFound>
    </Paper>
  );
}
