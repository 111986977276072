import styled from 'styled-components';

export const HeaderContent = styled.div`
  padding: 1rem 1.5rem 1rem 1.5rem;
`;
export const TrailingBar = styled.div`
  display: flex;
  justify-content: end;
  padding-bottom: 1rem;
`;
export const Icon = styled.div`
  color: white !important;
`;
