import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { CardMenu } from '../../../components/CardMenu';
import { HorizontalCarousel } from '../../../components/HorizontalCarousel';
import { Youtube } from '../../../components/Youtube';
import {
  BodyContainer,
  ListVideoContainer,
} from '../../../components/BodyStyle/style';
import * as Icon from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export const Body = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <BodyContainer padding={true}>
        <CardMenu
          title={
            <Typography
              variant="subtitle1"
              sx={{ color: '#707070' }}
              fontWeight={'normal'}
            >
              {'Acessar Proposta'}
            </Typography>
          }
          trailing={<Icon.ArrowForwardIos sx={{ color: '#707070' }} />}
          action={() => {
            navigate(`./show`);
          }}
        >
          <div>
            <Typography
              variant="subtitle2"
              sx={{ fontSize: 10, fontWeight: 600, color: '#707070' }}
            >
              Ver detalhes
            </Typography>
          </div>
        </CardMenu>
        <Youtube width="100%" embedId="labqcRLt7ZY" />
        <ListVideoContainer>
          <Typography
            variant="subtitle1"
            sx={{ color: '#707070', fontWeight: 'normal' }}
          >
            Saiba Mais
          </Typography>
          <HorizontalCarousel>
            <Youtube width="80%" embedId="V8mjGrBRxP4" />
            <Youtube width="80%" embedId="r2nPhxNAthA" />
            <Youtube width="80%" embedId="RxcP3dSWG88" />
          </HorizontalCarousel>
        </ListVideoContainer>
      </BodyContainer>
    </React.Fragment>
  );
};
