import React from 'react';
import GlobalStyle from './styles/global_style';
import Routes from './routes';
import { ThemeProvider } from '@mui/material';

import DefaultTheme from './themes/default_theme';

function App() {
  console.log(process.env);
  return (
    <ThemeProvider theme={DefaultTheme}>
      <GlobalStyle />

      <Routes />
    </ThemeProvider>
  );
}

export default App;
